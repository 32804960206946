text {
    user-select: none;
}

.ecosystem__module-path {
    fill: #eceff1;
    stroke: #cfd8dc;
    stroke-linejoin: round;
    stroke-width: 1px;
}

.html-in-svg__wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__logo {
        width: 100%;
        // height: 45%;
    }

    &__btn {
        cursor: pointer;
        font-size: 1rem;
        border-radius: 20px;
        padding: 3px 21px;
        border: 1px solid #78909c;
        background: none !important;
        color: #78909c;

    }
}