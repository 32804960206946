$e3d__grey--1: #eceff1;
$e3d__grey--2: #cfd8dc;
$e3d__grey--3: #b0bec5;
$e3d__grey--4: #90a4ae;
$e3d__grey--5: #78909c;
$e3d__grey--6: #607d8b;
$e3d__grey--7: #546e7a;
$e3d__grey--8: #455a64;
$e3d__grey--9: #37474f;
$e3d__grey--10: #263238;
$e3d__grey--11: #000a12;

$e3d__red: #ff1744;
$e3d__purple: #651fff;

$e3d__gradient--linear: linear-gradient(45deg,
    #d50000,
    #ff6d00,
    #ffea00,
    #00e676);