@import './_colors.scss';

.loader-circle {
  border-radius: 50%;
  background: linear-gradient(#d50000, #ff6d00, #ffea00, #00e676);
  animation: animate 0.75s linear infinite;
}

@keyframes animate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loader-circle-lvl2 {
  position: relative;
  border-radius: 50%;
  box-shadow: inset 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.loader-circle span {
  display: block;
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(#d50000, #ff6d00, #ffea00, #00e676);
  filter: blur(0.5px);
}