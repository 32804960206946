@import './_colors.scss';

.login-paper {
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // align-items: center;
  position: relative;
  width: 560px;
  height: 420px;
  border-radius: 5px;
  background-color: $e3d__grey--1;
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.5);
}

.login-paper__gradient {
  position: absolute;
  // width: 100%;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: $e3d__gradient--linear;
}

.login-paper__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 60px);
  width: 100%;
}

.login-paper__main__logo {
  // margin-top: 60px;
  height: 160px;
  display: flex;
  // justify-content: center;
  align-items: flex-end;
  width: 100%;

  svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.login-paper__main__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
}

.login-form__input-wrapper {
  position: relative;
  width: 280px;
  height: 35px;
  border-radius: 3px;
  border: 1px solid $e3d__grey--3;
}

.login-form__input-wrapper:focus-within {
  border: 1px solid $e3d__grey--10;

  label {
    color: $e3d__grey--10;
  }
}

.login-form__input-wrapper:first-of-type {
  margin-bottom: 45px;
}

.login-form__input-wrapper:last-of-type {
  margin-top: 45px;
}

.login-form__input-wrapper label {
  position: absolute;
  left: 50%;
  padding: 0 20px;
  background-color: $e3d__grey--1;
  font-weight: 500;
  color: $e3d__grey--4;
  transform: translate(-50%, -58%);
}

.login-form__input-wrapper input {
  display: block;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  background: none;
  text-align: center;
}

.login-form__input-wrapper input:focus {
  outline: none;
  padding: 0 10px;
  box-sizing: border-box;
}

.login-form__input-wrapper input::placeholder {
  padding: 0 10px;
}

.error-message {
  display: block;
  text-align: center;
  background-color: #ffcdd2;
  margin-top: 5px;
  padding: 5px 0;
  border-radius: 3px;
  color: $e3d__red;
  font-size: 0.9rem;
}

.error-message--visible {
  opacity: 1;
}

.error-message--unvisible {
  opacity: 0;
}

.login__btn-submit {
  width: 100%;
  height: 60px;
}

.login__btn-submit button,
.login__btn-submit input[type="submit"] {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: $e3d__grey--10;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.25);
  font-variant: all-small-caps;
  font-size: 1.25rem;
  color: white;
  font-weight: 700;
  letter-spacing: 0.05rem;
  transition: background-color 100ms ease-in;
  border: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    pointer-events: none;
    background-color: $e3d__grey--3;
  }
}

.login__btn-submit button:hover,
.login__btn-submit input[type="submit"]:hover {
  background-color: $e3d__grey--11;
}

.login__btn-submit button:active,
.login__btn-submit input[type="submit"]:active {
  background-color: #000;
}