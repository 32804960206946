.app-content {
  position: relative;
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    // top: 50%;
    // left: 50%;
    top: calc(50% - 500px);
    left: calc(50% - 500px);
    width: 1000px;
    height: 1000px;
    border-radius: 50%;
    background-color: #fff;
    mix-blend-mode: overlay;
    opacity: 0.25;
    transform-origin: center;
    // transform: translate(-50%, -50%);
    animation: scale 7s ease-out 0.5625s infinite;
    animation-fill-mode: both;
  }

  &:after {
    content: '';
    position: absolute;
    // top: 50%;
    // left: 50%;
    top: calc(50% - 500px);
    left: calc(50% - 500px);
    width: 1000px;
    height: 1000px;
    border-radius: 50%;
    background-color: #fff;
    mix-blend-mode: overlay;
    opacity: 0.25;
    transform-origin: center;
    // transform: translate(-50%, -50%);
    animation: scale 7s ease-out infinite;
  }

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 14px 42px 14px 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;

    &__user {
      display: flex;
      align-items: center;
      position: relative;

      &__infos {
        text-align: right;
        color: #fff;

        >div {
          &:first-of-type {
            font-size: 1.25rem;
            font-weight: 700;
          }

          &:last-of-type {
            font-size: 1rem;
          }
        }
      }

      &__avatar {
        position: relative;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background: red;
        margin-left: 14px;
        background: linear-gradient(45deg, #d50000, #ff6d00, #ffea00, #00e676);
        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);

        >div {
          position: absolute;
          width: 38px;
          height: 38px;
          border-radius: 50%;
          top: 2px;
          left: 2px;
          overflow: hidden;
          background-color: #eceff1;
        }
      }

      &__btn-collapse {
        button {
          cursor: pointer !important;
          background: none;
          border: none;
          margin-left: 7px;

          &:focus {
            outline: none;
          }

          svg {
            color: #fff;
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  &__body {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 1;

    &__svg {
      width: 100%;
      height: 90%;
      max-height: 550px;
      min-height: 450px;
    }
  }

  &__footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 35px 56px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    z-index: 100;

    span {
      margin-right: 7px;
    }

    a {
      cursor: pointer;

      img {
        height: 42px;
      }
    }
  }
}

.menu {
  position: absolute;
  background-color: #eceff1;
  bottom: -14px;
  right: 0;
  transform: translateY(100%);
  border-radius: 3px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);

  >div {
    cursor: pointer;
    padding: 7px 14px;
    color: #263238;
  }

  &:focus {
    outline: none;
  }
}

@keyframes scale {
  0% {
    transform: scale(0.25);
    opacity: 0.125;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }

}